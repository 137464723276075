require('./bootstrap');

//import Alpine from 'alpinejs';
//window.Alpine = Alpine;
//Alpine.start();
(function mainScript() {
    "use strict";
    const offcanvasToggle = document.querySelector(
      '[data-bs-toggle="offcanvas"]',
    );
    const offcanvasCollapse = document.querySelector(".offcanvas-collapse");
    offcanvasToggle.addEventListener("click", function () {
      offcanvasCollapse.classList.toggle("open");
    });
  })();
